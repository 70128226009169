import React from "react"
import Helmet from 'react-helmet'
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Heading from "../../components/heading/heading";
import ContentBlock from "../../components/contentBlock/contentBlock";
import ScrollAnimation from 'react-animate-on-scroll';
import Img from "gatsby-image";

const EnterprisePage = ({ data }) => {

   return (
    <Layout>
      <SEO title="Enterprise" />
      <Helmet bodyAttributes={{class: 'overflow-x-hidden'}} />
      <ScrollAnimation className="product-page-header mt-5" animateIn="fadeInUp" animateOnce={true}>
        <Heading
          title="Enterprise"
          copy="Being able to configure every part of your commerce infrastructure across your entire enterprise is critical to support growth. We understand how complicated it can be to manage a non-integrated system. Our Enterprise Back Office was built with operations in mind, with a rich set of store, product, loyalty and other configuration options available 24/7. Enterprise offers features that can help keep your c-store, restaurant, or any other retail store organized and well-equipped."
          align="center"
        />
        <Img fluid={data.file.childImageSharp.fluid} alt="enterprise header" />
      </ScrollAnimation>
      <ContentBlock
        imageCol="right"
        title="Built-In Features"
        copy="Managing your enterprise can be exhausting, so we want to give you tools to help. Within our built-in features, we offer tools for configuring store and product attributes, configuring and deploying POS screens and promotions, managing a built in loyalty platform, and so much more."
        imageName="enterprise-expansion-feature.png"
      />
      <ContentBlock
        imageCol="left"
        title="Above Store Management"
        copy="With above store management, you can more quickly and easily be in control of your store operations, so store associates can focus more on what matters, your customers! With effective dated changes, you can schedule updates to store, product, pricing and other attributes. Best of all, above store management allows for rapid deployment of changes to your point of sale configuration across your entire organization."
        imageName="enterprise-store-config.png"
      />
      <ContentBlock
        imageCol="right"
        title="Custom Event Triggers"
        copy="Traditional commerce platforms don’t allow you to react to specific events as they happen. With the RetailStack Commerce Platform you can react in real time to purchase events as they happen. Want to send a thank you push notification to a customer right after they check out in store? It’s all integrated. With scheduled events you can send a birthday reward to a customer or recapture customers with abandoned online shopping carts."
        imageName="enterprise-custom-event-triggers.png"
    />
      <ContentBlock
        imageCol="left"
        title="Robust Reporting"
        copy="Actionable analytics are crucial to the success of any retailer. The RetailStack Commerce Platform offers a robust set of out-of-the-box reports with a holistic picture into the sales of your entire enterprise. Easily access store reports and drill down to important analytics to keep track trends in real-time. We make this information easy to find and simple to decipher. If the built-in reports aren’t enough, you can build custom reports on any of the thousands of data points in the system or integrate with a business intelligence platform."
        imageName="enterprise-store-reporting.png"
      />
      <ContentBlock
        imageCol="right"
        title="Enterprise Integrations"
        copy="One of the most important features about our platform is the ability to integrate first- and third- party applications via our open APIs. The Enterprise Back Office serves as your hub for partner integrations like delivery service providers, inventory management systems, marketing automation platforms, and more."
        imageName="enterprise-first-and-third-party-integration.png"
      />
    </Layout>
  )
}

export default EnterprisePage

export const query = graphql`
  query {
    file(relativePath: { eq: "enterprise-system-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1170) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`